import * as FirebaseApp from 'firebase/app'
import * as FirebaseAuth from 'firebase/auth'
import * as FirebaseFirestore from 'firebase/firestore'
import * as FirebaseFunctions from 'firebase/functions'
import { getRemoteConfig } from 'firebase/remote-config'
import * as FirebaseStorage from 'firebase/storage'
import envFromWindowLocation from './env-from-window-location.js'

/*
 * Silence irritating warnings
 */
const _consoleWarn = console.warn
console.warn = (...args) => {
    const skipWarning = message => args.some(a => a.match && a.match(message))

    if (skipWarning(/React Router Future Flag Warning/)) return
    if (skipWarning(/You may test your Stripe.js integration over HTTP/)) return

    return _consoleWarn.apply(null, args)
}

/*
 Return configuration object for Firebase based on current environment
 * @sig getFirebaseConfiguration :: () -> Object
 */
const getFirebaseConfiguration = () => {
    const { env, useEmulator } = envFromWindowLocation()

    if (env === 'development')
        return {
            useEmulator,
            appId: '1:1015622064814:web:62a412c90556ae0bb7eb3f',
            apiKey: 'AIzaSyD0ttJDQf6DZxOyZTTqR6Knr20kAJ4oFEc',
            authDomain: 'development-18fcf.firebaseapp.com',
            projectId: 'development-18fcf',
            storageBucket: 'development-18fcf.appspot.com',
            messagingSenderId: '1015622064814',
            measurementId: 'G-17VFML8LCT',
        }

    if (env === 'demorangeio')
        return {
            appId: '1:111699888805:web:b090b67e997daaa5d91b4c',
            apiKey: 'AIzaSyBr4qLv61A1X5-WRgWTqYY1nIB7GIbWTkI',
            authDomain: 'demorangeio.firebaseapp.com',
            projectId: 'demorangeio',
            storageBucket: 'demorangeio.appspot.com',
            messagingSenderId: '111699888805',
        }

    if (env === 'staging-range-io')
        return {
            apiKey: 'AIzaSyDLm8T7Hh0wgbtAIvH3DKs0cf4fFDS2loo',
            appId: '1:442450513996:web:33c6d71303ac411e6fa4e9',
            authDomain: 'staging-range-io.firebaseapp.com',
            projectId: 'staging-range-io',
            storageBucket: 'staging-range-io.appspot.com',
            messagingSenderId: '442450513996',
            measurementId: 'G-H2BTM5K952',
        }

    if (env === 'production-range-io')
        return {
            apiKey: 'AIzaSyBCFzCC_6t4ltyUtTC7cUkKTbYwGH37aCo',
            appId: '1:229130128761:web:1f87882123fe70019973a8',
            authDomain: 'production-range-io.firebaseapp.com',
            projectId: 'production-range-io',
            storageBucket: 'production-range-io.appspot.com',
            messagingSenderId: '229130128761',
            measurementId: 'G-B66M7JBS6Q',
        }

    throw new Error("Don't understand environment named '" + env + "' must be development")
}

const configuration = getFirebaseConfiguration()

// Initialize Firebase
const app = FirebaseApp.initializeApp(configuration)
const auth = FirebaseAuth.getAuth(app)
const storage = FirebaseStorage.getStorage(app)
const firestore = FirebaseFirestore.getFirestore(app)
const remoteConfig = getRemoteConfig(app)

// no analytics for local
const analytics = null
const functions = FirebaseFunctions.getFunctions(app, 'us-west2')

// Possibly point to the emulators running on localhost.
if (configuration.useEmulator) {
    FirebaseStorage.connectStorageEmulator(storage, 'localhost', 9199)
    FirebaseAuth.connectAuthEmulator(auth, 'http://127.0.0.1:9099')
    FirebaseFunctions.connectFunctionsEmulator(functions, 'localhost', 5001)
    FirebaseFirestore.connectFirestoreEmulator(firestore, 'localhost', 9001)
}

const appCheck = null

const KNOCK_PUBLIC_API_KEY = 'pk_test_CXGOR6sWciWHrWKvF7Bwjqj8y1k59RyiMttM0lwi4L0'
const STRIPE_PUBLISHABLE_KEY =
    'pk_test_51JwfmLB0VhUzDU5ZWy4oN5bxPDCf4norEXPKZPhiuPnaR491JAQqjpzpMNAKRmaqxRUJF8jswsMNjYHdRPD8ucko00uO98b6cH'

export {
    app,
    auth,
    firestore,
    functions,
    storage,
    analytics,
    appCheck,
    remoteConfig,
    KNOCK_PUBLIC_API_KEY,
    STRIPE_PUBLISHABLE_KEY,
}
