import { Category } from '@range.io/basic-types'
import { mergeRight, pluck, without } from '@range.io/functional'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, FlexColumn, FlexRow, Text } from '../components-reusable/index.js'
import { ProjectChangedCommand } from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import CategoryEditor from './CategoryEditor.js'

const StyledOuterContainer = styled(FlexColumn, {
    height: '100vh',
    width: '100%',
    fontSize: 14,
    ai: 'center',
    justifyContent: 'start-center',
    transitionDuration: '0.4s',
    background: '$neutral10',

    position: 'absolute',
    zIndex: 100,
    overflow: 'auto',
    paddingTop: 80,
    paddingBottom: 80,
})

const StyledFlexColumn = styled(FlexColumn, {
    width: '80%',
    maxWidth: '50%',
    justifyContent: 'start',
})

const StyledTopBar = styled(FlexRow, {
    width: '100%',
    marginBottom: '24px',
})

const StyledPrimaryLabel = styled(Box, {
    width: 'auto',
    color: '$neutral04',
    fs: 32,
    fw: '700',
})

const StyledDescriptionLabel = styled(Text, {
    width: 'auto',
    color: '$neutral05',
    fs: 16,
    justifyContent: 'start',
    whiteSpace: 'wrap',
    pt: 16,
})

const CategoryManager = () => {
    const navigate = useNavigate()
    const { workspaceId, projectId } = useParams()
    const { runCommand } = useCommandHistory()

    const currentProject = useSelector(ReduxSelectors.selectedProject)
    const allCategories = useSelector(ReduxSelectors.categories)

    const [initialCategories, setInitialCategories] = useState()
    const [categories, setCategories] = useState(initialCategories)

    useEffect(() => {
        const categories = allCategories.sort((a, b) => a.order - b.order)
        setInitialCategories(categories)
    }, [allCategories])

    const handleClose = () => {
        let validCategories = categories.filter(category => category.name.length) // remove empty
        validCategories = validCategories.map((cat, i) => Category.from(mergeRight(cat, { order: i }))) // renumber the order

        const initialCategoryIds = pluck('id', initialCategories) // the ones we started editing
        const newCategoryIds = pluck('id', validCategories) // the ones we try to save now
        const defaultCategoryId = initialCategories.find(cat => cat.isDefault).id

        const deletedCategoryIds = without(newCategoryIds, initialCategoryIds) // check if any were deleted
        const deletedCategoriesObject = deletedCategoryIds?.reduce(
            (acc, id) => ({ ...acc, [id]: defaultCategoryId }),
            {}
        )

        // save categories
        runCommand(
            ProjectChangedCommand.Outbound(currentProject.id, {
                categories: validCategories,
                deletedCategories: deletedCategoriesObject,
            })
        )

        if (window.history.state?.usr?.safeToNavigateBack) navigate(-1)
        else navigate(`/${workspaceId}/${projectId}`)
    }

    return (
        <StyledOuterContainer>
            <StyledFlexColumn>
                <StyledTopBar>
                    <FlexColumn css={{ width: 'inherit' }}>
                        <Button onClick={handleClose} css={{ width: '70px', mb: '24px' }} variant="primary" size="lg">
                            <Text>Close</Text>
                        </Button>
                        <StyledDescriptionLabel css={{ fw: '600' }}>{currentProject.name}</StyledDescriptionLabel>
                        <FlexRow css={{ ai: 'center', gap: 8, width: '100%', pt: 4 }}>
                            <StyledPrimaryLabel>Project Category Manager</StyledPrimaryLabel>
                        </FlexRow>
                        <StyledDescriptionLabel>
                            Pin categories group task pins of a specific type. Each pin has only one category. Manage
                            your existing project categories below.
                        </StyledDescriptionLabel>
                    </FlexColumn>
                </StyledTopBar>
                {initialCategories && (
                    <CategoryEditor defaultCategories={initialCategories} onCategoriesChange={setCategories} />
                )}
            </StyledFlexColumn>
        </StyledOuterContainer>
    )
}

export default CategoryManager
